import React from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { Lightbulb, Palette, Code, Rocket } from 'lucide-react'

const processSteps = [
  { title: "1. Ideation & Strategy", description: "Collaborating closely with clients to understand their vision and develop a comprehensive strategy for bringing it to life.", icon: Lightbulb },
  { title: "2. Design & Prototyping", description: "Creating intuitive and visually stunning designs, followed by interactive prototypes to validate concepts early.", icon: Palette },
  { title: "3. Development & Testing", description: "Building robust, scalable solutions using cutting-edge technologies, with rigorous testing at every stage.", icon: Code },
  { title: "4. Launch & Optimization", description: "Ensuring a smooth launch and providing ongoing support and optimization to maximize impact and user satisfaction.", icon: Rocket }
]

const Process = () => {
  const controls = useAnimation()
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })

  React.useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <motion.section 
      id="process" 
      className="py-24 bg-gray-800"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-4xl font-bold mb-12 text-center text-white"
          variants={itemVariants}
        >
          My Process
        </motion.h2>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-12"
          variants={containerVariants}
        >
          {processSteps.map((step, index) => (
            <motion.div 
              key={index}
              className="bg-gray-900 p-8 rounded-lg shadow-xl"
              variants={itemVariants}
            >
              <div className="flex items-center mb-4">
                <step.icon className="text-teal-400 mr-3" size={24} />
                <h3 className="text-2xl font-semibold text-teal-400">{step.title}</h3>
              </div>
              <p className="text-gray-300">{step.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Process