import React, { useState } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useTheme } from './ThemeContext'
import axios from 'axios'
import { FaPhone, FaEnvelope, FaLinkedin } from 'react-icons/fa'

const Contact = () => {
  const controls = useAnimation()
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })
  const { isDarkMode } = useTheme()
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState("")

  React.useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)

    try {
      await axios.post('https://formspree.io/f/myyavaqj', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setShowModal(true)
      setName(formData.get('name'))
      form.reset()
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }

  const handlePhoneClick = () => {
    window.location.href = 'tel:925-818-4544'
  }

  const handleEmailClick = () => {
    window.location.href = 'mailto:ahmaadidrees@gmail.com'
  }

  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/ahmaad-idrees/')
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <motion.section 
      id="contact" 
      className={`py-24 ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className={`text-4xl font-bold mb-12 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
          variants={itemVariants}
        >
          Let's Get In Touch
        </motion.h2>
        
        <motion.div className="flex justify-center space-x-8 mb-12" variants={itemVariants}>
          <button onClick={handlePhoneClick} className="text-teal-500 hover:text-teal-600">
            <FaPhone size={24} />
          </button>
          <button onClick={handleEmailClick} className="text-teal-500 hover:text-teal-600">
            <FaEnvelope size={24} />
          </button>
          <button onClick={handleLinkedInClick} className="text-teal-500 hover:text-teal-600">
            <FaLinkedin size={24} />
          </button>
        </motion.div>

        <motion.div 
          className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-8 rounded-lg shadow-xl max-w-2xl mx-auto`}
          variants={itemVariants}
        >
          <form onSubmit={handleFormSubmit}>
            <motion.div className="mb-6" variants={itemVariants}>
              <label htmlFor="name" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Name</label>
              <motion.input 
                type="text" 
                id="name" 
                name="name"
                className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'} border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500`}
                whileFocus={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                required
              />
            </motion.div>
            <motion.div className="mb-6" variants={itemVariants}>
              <label htmlFor="email" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Email</label>
              <motion.input 
                type="email" 
                id="email" 
                name="email"
                className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'} border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500`}
                whileFocus={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                required
              />
            </motion.div>
            <motion.div className="mb-6" variants={itemVariants}>
              <label htmlFor="subject" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Subject</label>
              <motion.input 
                type="text" 
                id="subject" 
                name="subject"
                className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'} border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500`}
                whileFocus={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                required
              />
            </motion.div>
            <motion.div className="mb-6" variants={itemVariants}>
              <label htmlFor="message" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Message</label>
              <motion.textarea 
                id="message" 
                name="message"
                rows="4" 
                className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-gray-900'} border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500`}
                whileFocus={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                required
              ></motion.textarea>
            </motion.div>
            <motion.button 
              type="submit" 
              className="w-full bg-gradient-to-r from-teal-500 to-teal-500 text-white px-6 py-3 rounded-md font-semibold hover:from-teal-600 hover:to-purple-600 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              Send Message
            </motion.button>
          </form>
        </motion.div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-8 rounded-lg`}>
            <h3 className="text-2xl font-bold mb-4">Your message has been sent!</h3>
            <p>Thank you for reaching out {name}, I will get back to you shortly.</p>
            <button 
              onClick={closeModal}
              className="mt-4 bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </motion.section>
  )
}

export default Contact