import React from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { ArrowRight } from 'lucide-react'
import { useTheme } from './ThemeContext'
import { useNavigate } from 'react-router-dom'

const Showcase = ({ showcaseProjects }) => {
  const controls = useAnimation()
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })
  const { isDarkMode } = useTheme()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  // Function to group projects into sets of three
  const groupProjects = (projects) => {
    const grouped = [];
    for (let i = 0; i < projects.length; i += 3) {
      grouped.push(projects.slice(i, i + 3));
    }
    return grouped;
  }

  const groupedProjects = groupProjects(showcaseProjects);

  const handleViewAllProjectsClick = () => {
    navigate('/projects');
    window.scrollTo(0, 0);
  }

  return (
    <motion.section 
      id="showcase" 
      className={`py-12 sm:py-16 md:py-24 ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold mb-8 sm:mb-12 md:mb-16 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
          variants={itemVariants}
        >
          Featured Projects
        </motion.h2>
        {groupedProjects.map((group, groupIndex) => (
          <motion.div 
            key={groupIndex}
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12"
            variants={containerVariants}
          >
            {group.map((project, index) => (
              <motion.div 
                key={index} 
                className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300`}
                variants={itemVariants}
              >
                <div className="relative h-48 bg-gradient-to-br from-teal-500 to-teal-500">
                  <h3 className="absolute inset-0 flex items-center justify-center text-xl sm:text-2xl font-bold text-white px-4 text-center">
                    {project.title}
                  </h3>
                </div>
                <div className="p-6">
                  <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-4 text-sm sm:text-base`}>{project.description}</p>
                  <motion.button 
                    className="text-teal-400 font-semibold hover:text-teal-300 transition duration-300 flex items-center text-sm sm:text-base"
                    whileHover={{ x: 5 }}
                  >
                    Explore Project
                    <ArrowRight className="ml-2" size={16} />
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </motion.div>
        ))}
        <motion.div 
          className="mt-8 sm:mt-12 md:mt-16 text-center"
          variants={itemVariants}
        >
          <button 
            onClick={handleViewAllProjectsClick}
            className="inline-block bg-teal-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-teal-600 transition duration-300"
          >
            View All Projects
          </button>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Showcase