import React from 'react';
import { ArrowRight, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom'; // Make sure to import Link from react-router-dom

const Hero = () => {
  return (
    <section id="home" className="min-h-screen flex flex-col items-center justify-center relative overflow-hidden bg-white dark:bg-gray-900 transition-colors duration-300 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto w-full">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 sm:mb-6 animate-text-shadow text-gray-900 dark:text-white transition-colors duration-300 leading-tight">
            Innovating Digital
            <span className="text-teal-400 block sm:inline"> Experiences</span>
          </h1>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8 animate-fade-in-up animation-delay-200 text-gray-700 dark:text-gray-300 transition-colors duration-300 max-w-2xl mx-auto">
            Transforming ideas into cutting-edge web and mobile solutions.
          </p>
          <Link
            to="/services"
            className="inline-flex items-center bg-teal-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:bg-teal-400 transition duration-300 animate-fade-in-up animation-delay-400 text-sm sm:text-base transform hover:scale-105 hover:shadow-lg"
          >
            Start Your Project
            <ArrowRight className="ml-2" size={16} />
          </Link>
        </div>
        <div className="mt-12 sm:mt-16 text-center animate-bounce">
          <ChevronDown size={24} className="mx-auto text-teal-400" />
          <span className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 transition-colors duration-300">Scroll to explore</span>
        </div>
      </div>
    </section>
  );
};

export default Hero;