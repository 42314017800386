import React from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useTheme } from './ThemeContext'

const Journey = () => {
  const controls = useAnimation()
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })
  const { isDarkMode } = useTheme()

  React.useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <motion.section 
      id="journey" 
      className={`py-24 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          className="md:flex md:items-center md:justify-between"
          variants={containerVariants}
        >
          <motion.div 
            className="md:w-1/2 md:pr-12"
            variants={itemVariants}
          >
            <h2 className={`text-4xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>About Me</h2>
            <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-4`}>
              As a forward-thinking digital product specialist, I combine creativity with technical expertise 
              to craft innovative web and mobile solutions. My approach centers on user-focused design and 
              cutting-edge technology to create impactful digital experiences.
            </p>
            <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
              With a passion for staying ahead of industry trends and a commitment to excellence, I help 
              businesses and individuals bring their most ambitious digital visions to life. Let's collaborate 
              to create something truly extraordinary for your next project.
            </p>
          </motion.div>
          <motion.div 
            className="md:w-1/2 mt-12 md:mt-0"
            variants={itemVariants}
          >
            <motion.div 
              className="bg-teal-500 text-white p-8 rounded-lg shadow-xl"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="text-2xl font-bold mb-4">Skills & Expertise</h3>
              <ul className="space-y-2">
                <motion.li variants={itemVariants}>• Full-stack Development</motion.li>
                <motion.li variants={itemVariants}>• Mobile App Architecture</motion.li>
                <motion.li variants={itemVariants}>• UI/UX Innovation</motion.li>
                <motion.li variants={itemVariants}>• AI & Machine Learning Integration</motion.li>
                <motion.li variants={itemVariants}>• Education Technology</motion.li>
              </ul>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Journey