import React from 'react';
import PortfolioMain from './components/PortfolioMain';
import { ThemeProvider } from './components/ThemeContext';

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <PortfolioMain />
      </ThemeProvider>
    </div>
  );
}

export default App;