import { Code, Linkedin, Mail } from 'lucide-react'
import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <p>&copy; 2024 Ahmaad Idrees. All rights reserved.</p>
            </div>
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-white transition duration-300" aria-label="View code samples">
                <Code size={24} />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition duration-300" aria-label="LinkedIn profile">
                <Linkedin size={24} />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition duration-300" aria-label="Email contact">
                <Mail size={24} />
              </a>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer