import React from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { Monitor, Smartphone, Lightbulb, Cloud } from 'lucide-react'
import { useTheme } from './ThemeContext'
import { Link, useNavigate } from 'react-router-dom'

const iconMap = {
  'Web Development': Monitor,
  'UI/UX Innovation': Lightbulb,
  'Mobile App Design': Smartphone
}

const Services = ({ services }) => {
  const controls = useAnimation()
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true, amount: 0.3 })
  const { isDarkMode } = useTheme()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }
  }, [controls, isInView])

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  const handleLearnMoreClick = () => {
    navigate('/services');
    window.scrollTo(0, 0);
  }

  return (
    <motion.section 
      id="services" 
      className={`py-12 sm:py-16 md:py-24 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold mb-8 sm:mb-12 md:mb-16 text-center ${isDarkMode ? 'text-white' : 'text-gray-900'}`}
          variants={itemVariants}
        >
          Core Services
        </motion.h2>
        <motion.div 
          className="flex flex-col lg:flex-row gap-6 sm:gap-8"
          variants={containerVariants}
        >
          {services.map((service, index) => {
            const IconComponent = iconMap[service.title] || Cloud;
            return (
              <motion.div 
                key={index} 
                className={`${isDarkMode ? 'bg-gray-900' : 'bg-white'} p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 transform hover:-translate-y-2 flex-1`}
                variants={itemVariants}
              >
                <div className="flex items-center mb-4">
                  <IconComponent className="text-teal-400 mr-3" size={24} />
                  <h3 className="text-lg sm:text-xl font-semibold text-teal-400">{service.title}</h3>
                </div>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} text-sm sm:text-base`}>{service.description}</p>
              </motion.div>
            )
          })}
        </motion.div>
        <motion.div 
          className="mt-8 sm:mt-12 md:mt-16 text-center"
          variants={itemVariants}
        >
          <button 
            onClick={handleLearnMoreClick}
            className="inline-block bg-teal-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-teal-600 transition duration-300"
          >
            Learn More
          </button>
        </motion.div>
      </div>
    </motion.section>
  )
}

export default Services