import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from './ThemeContext';
import Process from './Process';
import { ArrowRight, Smartphone, Laptop, Globe, Zap, MessageCircle, Clock, Lightbulb, RefreshCw } from 'lucide-react';
import axios from 'axios';

const services = [
  { title: "Web Development", description: "Creating responsive and dynamic web applications." },
  { title: "Mobile App Design", description: "Designing intuitive and engaging mobile experiences." },
  { title: "UI/UX Innovation", description: "Crafting user-centric interfaces that delight and inspire." },
];

const detailsItems = [
  { title: "Responsive Design", description: "Your digital product will look great on every screen size.", icons: [Smartphone, Laptop] },
  { title: "Fast Loading Speeds", description: "Your digital product will be optimized with fast loading speeds regardless of the amount of content.", icons: [Zap, Globe] },
  { title: "Excellent Communication", description: "Through continuous communication, we will ensure your digital product looks exactly how you want.", icons: [MessageCircle] },
  { title: "Rapid Turnaround", description: "The initial draft of your digital product will be completed within 1-2 weeks, from there fine-tuning will be applied as needed.", icons: [Clock] },
  { title: "Customizable", description: "The sky is the limit, if you can think of it, it can be added to your digital product!", icons: [Lightbulb] },
  { title: "Maintenance", description: "I can either continuously add content to your digital product or set your product up to be easily maintained by your choosing.", icons: [RefreshCw] },
];

const ServicesPage = () => {
  const { isDarkMode } = useTheme();
  const [selectedService, setSelectedService] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    formData.append("service", selectedService.title);

    try {
      await axios.post('https://formspree.io/f/myyavaqj', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setShowModal(true);
      setName(formData.get('name'));
      form.reset();
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedService(null);
  };

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <motion.h1 
          style={{marginTop: 90}}
          className="text-5xl font-bold mb-16 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What My Services Include
        </motion.h1>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-24"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {detailsItems.map((item, index) => (
            <motion.div 
              key={index} 
              className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg shadow-md`}
              variants={itemVariants}
            >
              <div className="flex justify-center mb-4">
                {item.icons.map((Icon, iconIndex) => (
                  <Icon key={iconIndex} size={48} className="text-teal-400 mx-2" />
                ))}
              </div>
              <h2 className="text-xl font-semibold mb-4 text-center">{item.title}</h2>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} text-center`}>{item.description}</p>
            </motion.div>
          ))}
        </motion.div>

        <Process />

        <motion.h2 
          className="text-4xl font-bold my-16 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Select A Service
        </motion.h2>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-24"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {services.map((service, index) => (
            <motion.div 
              key={index} 
              className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300`}
              variants={itemVariants}
            >
              <h2 className="text-2xl font-semibold mb-4">{service.title}</h2>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-4`}>{service.description}</p>
              <motion.button 
                className="bg-teal-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-teal-600 transition duration-300 flex items-center justify-center w-full"
                onClick={() => handleServiceSelect(service)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
                <ArrowRight className="ml-2" size={16} />
              </motion.button>
            </motion.div>
          ))}
        </motion.div>

        {selectedService && (
          <motion.div 
            className={`mt-24 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} p-8 rounded-lg shadow-xl`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-6">{selectedService.title}</h2>
            <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-6`}>{selectedService.description}</p>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium mb-2">Name</label>
                <input type="text" id="name" name="name" className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} border rounded-md`} required />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
                <input type="email" id="email" name="email" className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} border rounded-md`} required />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
                <textarea id="message" name="message" rows="4" className={`w-full px-3 py-2 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} border rounded-md`} required></textarea>
              </div>
              <div className="flex justify-between">
                <button 
                  type="button" 
                  className="bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition duration-300"
                  onClick={() => setSelectedService(null)}
                >
                  Back
                </button>
                <button 
                  type="submit" 
                  className="bg-teal-500 text-white px-6 py-2 rounded-md hover:bg-teal-600 transition duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </motion.div>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-8 rounded-lg max-w-md`}>
            <h3 className="text-2xl font-bold mb-4">Your message has been sent!</h3>
            <p>Thank you for reaching out {name}, I will get back to you shortly about the {selectedService.title} service.</p>
            <button 
              onClick={closeModal}
              className="mt-6 bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesPage;