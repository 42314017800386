import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Hero from './Hero';
import Journey from './Journey';
import Services from './Services';
import Showcase from './Showcase';
import Contact from './Contact';
import Footer from './Footer';
import { ThemeProvider, useTheme } from './ThemeContext';

// New components for separate routes
import ProjectsPage from './ProjectsPage';
import ServicesPage from './ServicesPage';

const PortfolioContent = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [activeSection, setActiveSection] = useState('home');
  const { isDarkMode } = useTheme();
  const location = useLocation();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;

      setScrollProgress(scroll);

      // Determine active section
      const sections = ['home',  'services', 'showcase','journey', 'contact'];
      const navbarHeight = 128; // Adjust this value based on your navbar height

      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section) {
          const rect = section.getBoundingClientRect();
          if (rect.top <= navbarHeight) {
            setActiveSection(sections[i]);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const journeySteps = [
    { year: '2018', title: 'Started Freelancing', description: 'Embarked on my journey as a freelance web developer.' },
    { year: '2020', title: 'Expanded to Mobile', description: 'Ventured into mobile app development, creating innovative iOS and Android apps.' },
    { year: '2022', title: 'AI Integration', description: 'Began incorporating AI and machine learning into web and mobile projects.' },
    { year: '2024', title: 'Blockchain Exploration', description: 'Started exploring blockchain technology for decentralized applications.' },
  ];

  const services = [
    { title: 'Web Development', description: 'Creating responsive and dynamic web applications.' },
    { title: 'Mobile App Design', description: 'Designing intuitive and engaging mobile experiences.' },
    { title: 'UI/UX Innovation', description: 'Crafting user-centric interfaces that delight and inspire.' },
  ];

  const showcaseProjects = [
    { 
      title: 'Wolf Windows', 
      description: 'Website for a residential exterior cleaning company. The site showcases various services offered, including window cleaning, pressure washing, and gutter cleaning. It features an innovative instant quote calculator, allowing potential customers to receive immediate pricing estimates based on their specific needs.',
      path:'/images/wolf.png'
    },
    { 
      title: 'MaadPod', 
      description: 'A custom-built MP3 player and downloader application using React Native. This app allows users to search for music across YouTube and SoundCloud, then download and play MP3 files directly on their device. It features a sleek interface, playlist management, and seamless integration with the device\'s file system.',
      path:'/images/powellmec.png'
    },
    { 
      title: 'Portfolio v1', 
      description: 'My first professional portfolio website. As a developer committed to continuous improvement, this initial version served as a foundation for showcasing my early projects and skills. It reflects my growth in web design and development, featuring responsive layouts, interactive elements, and a clean, modern aesthetic.',
      path:'/images/SealTech.png'
    },
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-300">
        <Navigation toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} activeSection={activeSection} />

        <div 
          className="fixed top-0 left-0 h-1 bg-gradient-to-r from-teal-400 to-teal-400 z-50 transition-all duration-300 ease-out"
          style={{ width: `${scrollProgress * 100}%` }}
        />

        <Routes>
          <Route path="/" element={
            <>
              <div id="home">
                <Hero />
              </div>
              <div id="services">
                <Services services={services} />
              </div>
              <div id="showcase">
                <Showcase showcaseProjects={showcaseProjects} />
              </div>
              <div id="journey">
                <Journey journeySteps={journeySteps} />
              </div>
              <div id="contact">
                <Contact />
              </div>
            </>
          } />
          <Route path="/projects" element={<ProjectsPage projects={showcaseProjects} />} />
          <Route path="/services" element={<ServicesPage services={services} />} />
        </Routes>

        <Footer />
      </div>
    </div>
  );
};

const PortfolioMain = () => (
  <Router>
    <PortfolioContent />
  </Router>
);

const ThemedPortfolioMain = () => (
  <ThemeProvider>
    <PortfolioMain />
  </ThemeProvider>
);

export default ThemedPortfolioMain;