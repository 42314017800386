import { Menu, X } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from './ThemeContext'
import ThemeToggle from './ThemeToggle'
import { motion, AnimatePresence } from 'framer-motion'

const Navigation = ({ toggleMenu, isMenuOpen, activeSection }) => {
  const { isDarkMode } = useTheme();
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 920);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menuVariants = {
    closed: {
      opacity: 0,
      y: -20,
      transition: { duration: 0.2 },
    },
    open: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2 },
    },
  };

  const navItems = [
    { name: 'Home', path: '/', section: 'home' },
    { name: 'Services', path: '/services', section: 'services' },
    { name: 'Projects', path: '/projects', section: 'projects' },
    { name: 'About', path: '/#journey', section: 'journey' },
    { name: 'Contact', path: '/#contact', section: 'contact' },
  ];

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      const navbarHeight = 128; // Adjust this value based on your navbar height
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - navbarHeight;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleNavClick = (path, section) => {
    if (isMenuOpen) toggleMenu();

    if (path === '/' || path.startsWith('/#')) {
      if (location.pathname !== '/') {
        navigate('/');
        // Wait for navigation to complete before scrolling
        setTimeout(() => scrollToSection(section), 100);
      } else {
        scrollToSection(section);
      }
    } else {
      navigate(path);
      // Scroll to top for other pages
      window.scrollTo(0, 0);
    }
  };

  const isActive = (item) => {
    if (location.pathname !== '/') return location.pathname === item.path;
    return activeSection === item.section;
  };

  return (
    <nav className={`fixed w-full ${isDarkMode ? 'bg-gray-900' : 'bg-white'} bg-opacity-90 backdrop-blur-md z-50 transition-all duration-300`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-24 md:h-32">
          <div className="flex items-center">
            <Link to="/" className="flex items-center" onClick={() => handleNavClick('/', 'home')}>
              <div className="w-12 h-12 sm:w-16 sm:h-16 md:w-24 md:h-24 mr-2 md:mr-4 flex items-center justify-center bg-teal-500 rounded-lg overflow-hidden">
                <img 
                  src={isDarkMode ? "/images/BlackLogo.png" : "/images/BlackLogo.png"}
                  alt="Logo" 
                  className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] md:w-[200px] md:h-[200px] object-contain transform -translate-y-2 sm:-translate-y-3 md:-translate-y-5"
                />
              </div>
              <span className={`text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-teal-500 transition-all duration-300`}>
                Ahmaad Idrees
              </span>
            </Link>
          </div>
          {!isMobile && (
            <div className="flex items-center space-x-4 sm:space-x-8">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  onClick={() => handleNavClick(item.path, item.section)}
                  className={`text-xs sm:text-sm uppercase tracking-wider ${
                    isActive(item)
                      ? 'text-teal-400' 
                      : isDarkMode 
                        ? 'text-gray-300 hover:text-white' 
                        : 'text-gray-600 hover:text-gray-900'
                  } transition duration-300`}
                >
                  {item.name}
                </Link>
              ))}
              <ThemeToggle />
            </div>
          )}
          {isMobile && (
            <div className="flex items-center">
              <ThemeToggle />
              <button onClick={toggleMenu} className={`ml-4 ${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'}`}>
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Mobile menu */}
      <AnimatePresence>
        {isMobile && isMenuOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className={`absolute left-0 w-full ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} bg-opacity-95`}
          >
            <div className="px-4 py-6 space-y-4">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  onClick={() => handleNavClick(item.path, item.section)}
                  className={`block text-lg uppercase tracking-wider ${
                    isActive(item)
                      ? 'text-teal-400'
                      : isDarkMode
                        ? 'text-gray-300 hover:text-white'
                        : 'text-gray-600 hover:text-gray-900'
                  } transition duration-300`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navigation;