import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from './ThemeContext';
import { ExternalLink, Download, ChevronDown, ChevronUp } from 'lucide-react';

const projects = [
      {
        id: 1,
        title: 'Wolf Windows',
        description: 'Built my own version of the command line shell in C.  Implemented several features of the command line including changing directories,displaying the history of commands entered, rerunning specific commands, and more ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/wolf.png',
        link: 'Powellmec.com',
      },
      {
        id: 2,
        title: 'Powell Mechanical',
        description: 'Built my own version of the command line shell in C.  Implemented several features of the command line including changing directories,displaying the history of commands entered, rerunning specific commands, and more ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/powellmec.png',
        link: 'Powellmec.com',
      },
      {
        id: 2.5,
        title: 'Aneelas Kitchen',
        description: 'Built my own version of the command line shell in C.  Implemented several features of the command line including changing directories,displaying the history of commands entered, rerunning specific commands, and more ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/aneelas.png',
        link: 'https://ahmaadidrees.netlify.app/',
      },
      {
        id: 3,
        title: 'Seal Tech Pavement Co.',
        description: 'Website for an Asphalt company, the website provides potential clients information as well as the option to recieve a free quote. This website is currently in development, but please feel free to check it out :)',
        tags: ['react', 'javascript', 'html', 'css'],
        image: '/images/SealTech.png',
        link: 'https://shieldsealpavementco.netlify.app/',
      },
      {
        id: 1.5,
        title: 'Portfolio v1',
        description: 'Built my own version of the command line shell in C.  Implemented several features of the command line including changing directories,displaying the history of commands entered, rerunning specific commands, and more ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/portfoliov1.png',
        link: 'https://ahmaadidrees.netlify.app/',
      },
      {
        id: 4,
        title: 'MaadPod',
        description: 'Build my own mp3 player/downloader using react native. This application retrieves search results from either youtube or soundcloud and then gives users the options to download the mp3 files of their search results onto their devices file system.',
        tags: ['react', 'react native','youtube api', 'soundcloud api', 'node.js'],
        image: '/images/MaadPod2.png',
        link: 'https://github.com/ahmaadidrees/MaadPod',
      },

      {
        id: 5,
        title: 'Blockchain',
        description: 'Created block and blockchain structs in GoLang. Implement proof of work as well as Nakamoto consensus to verify blocks prior to being added to the blockchain',
        tags: ['blockchain', 'crypto','golang'],
        image: '/images/bitcoin.png',
        link: 'https://github.com/ahmaadidrees/simpleBlockchain/tree/main/P4/data',
      },
      {
        id: 6,
        title: 'Search Engine',
        description: 'Built a search engine with an inverted index data structure, a searchable inverted index, a multithreaded inverted index, and a web crawler ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/search.png',
        link: 'https://github.com/ahmaadidrees/searchEngine/tree/main/src',
      },

      
      {
        id: 7,
        title: 'Kade\'s Kloset',
        description: 'This is a fullstack clothing store, I used the commerce.js api to handle clothing store functionality, and the stripe api to handle payments in the backend. The website owner currently does not use it but please still check it out :) ',
        tags: ['react','express','node.js', 'stripe','commerce.js'],
        image: '/images/kadeskloset.png',
        link: 'https://www.thekadeskloset.com/',
      },
      {
        id: 8,
        title: 'Arbor Connection',
        description: 'Live menu for a cannabis dispensary.  This website was built using the commerce.js api, a really cool api that provides a backend for inventory based websites.  If you are 21+ please feel free to check it out :)',
        tags: ['react','commerce.js'],
        image: '/images/arbor.png',
        link: 'https://www.arborconnect.net/',
      },
      
      
      
      
      {
        id: 9,
        title: 'True Split',
        description: 'Simple bill splitter web application that accurately calculates your exact portion of the bill.  This was my first web application that I made using react, I love how this website reminds me of how far I\'ve come in such a short time :)',
        tags: ['react', 'javascript','html', 'css'],
        image: '/images/truesplit.png',
        link: 'https://truesplit.netlify.app/',
      },
      {
        id: 10,
        title: 'MySplit',
        description: 'Bill splitter iphone application that accurately calculates your exact portion of the bill by retrieving the local sales tax based on an entered city.  This was my first react mobile application that I made using react native.',
        tags: ['react', 'javascript','html', 'css'],
        image: '/images/mySplitScreenShot.png',
        link: 'https://github.com/ahmaadidrees/MySplit-Frontend',
      },
     
      
      {
        id: 11,
        title: 'MyShell',
        description: 'Built my own version of the command line shell in C.  Implemented several features of the command line including changing directories,displaying the history of commands entered, rerunning specific commands, and more ',
        tags: ['java', 'inverted index','web crawler'],
        image: '/images/git.png',
        link: 'https://github.com/ahmaadidrees/myShell',
      },
      
      
    ];

const Projects = ({ isPage }) => {
  const { isDarkMode } = useTheme();
  const [visibleProjects, setVisibleProjects] = useState(6);

  const handleLoadMore = () => {
    setVisibleProjects(prev => Math.min(prev + 3, projects.length));
  };

  const handleShowLess = () => {
    setVisibleProjects(6);
  };

  const handleDownloadResume = () => {
    const resumeUrl = '/files/resume.pdf';
    window.open(resumeUrl, '_blank');
  };

  return (
    <motion.div
      className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <motion.h1
         style={{marginTop: 90}}
          className="text-5xl font-bold mb-12 text-center"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Projects
        </motion.h1>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1
              }
            }
          }}
        >
          {projects.slice(0, visibleProjects).map((project) => (
            <motion.div
              key={project.id}
              className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'} rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300`}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
              }}
            >
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-4`}>{project.description}</p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.tags.map((tag) => (
                    <span key={tag} className="px-2 py-1 bg-teal-500 text-white text-sm rounded-full">
                      {tag}
                    </span>
                  ))}
                </div>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-teal-500 hover:text-teal-600 transition-colors duration-200"
                >
                  View Project <ExternalLink size={16} className="ml-1" />
                </a>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {projects.length > visibleProjects && (
          <div className="text-center mt-8">
            <button
              onClick={handleLoadMore}
              className="bg-teal-500 text-white px-6 py-2 rounded-full hover:bg-teal-600 transition-colors duration-200 inline-flex items-center"
            >
              Load More <ChevronDown size={16} className="ml-1" />
            </button>
          </div>
        )}

        {visibleProjects > 6 && (
          <div className="text-center mt-4">
            <button
              onClick={handleShowLess}
              className="bg-gray-500 text-white px-6 py-2 rounded-full hover:bg-gray-600 transition-colors duration-200 inline-flex items-center"
            >
              Show Less <ChevronUp size={16} className="ml-1" />
            </button>
          </div>
        )}

        {/* <div className="text-center mt-12">
          <button
            onClick={handleDownloadResume}
            className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition-colors duration-200 inline-flex items-center"
          >
            <Download size={20} className="mr-2" />
            Download Resume
          </button>
        </div> */}
      </div>
    </motion.div>
  );
};

export default Projects;